.blog-author-section,
.blog-text-section {
	.redactor-textfield,
	.textfield-blog {
		@apply text-body-text;

		p {
			@apply text-body;
			@apply leading-6;
			@apply font-wotfard-light;
			@apply mb-4;
			
			strong {
				@apply font-bold;
				@apply text-black;
			}

			a {
				@apply underline;
			}
	
		}

		a {
			@apply underline;

			&:hover {
				@apply text-heading-dark;
			}
		}

		ul {
			li {
				@apply mb-0.5;
				@apply list-disc;
				@apply list-outside;
				@apply ml-list-item;
				@apply text-body;
				@apply font-wotfard-light;

				@screen md {
					@apply mb-1;
				}
			}

			li::marker {
				@apply text-grey-light;
				@apply text-bullet-point;
			}
		}
	}
}
