@font-face {
	font-family: "silka-bold";
	font-display: swap;
	src: url("/src/fonts/silka-bold-webfont.woff2") format("woff2");
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: "silka-regular";
	font-display: swap;
	src: url("/src/fonts/silka-regular-webfont.woff2") format("woff2");
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: "silka-lightitalic";
	font-display: swap;
	src: url("/src/fonts/silka-lightitalic-webfont.woff2") format("woff2");
	font-weight: normal;
	font-style: normal;
}

///// New fonts

@font-face {
	font-family: "raleway-regular";
	font-display: swap;
	src: url("/src/fonts/raleway-regular.woff2") format("woff2");
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: "raleway-medium";
	font-display: swap;
	src: url("/src/fonts/raleway-medium.woff2") format("woff2");
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: "raleway-bold";
	font-display: swap;
	src: url("/src/fonts/raleway-bold.woff2") format("woff2");
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: "wotfard-light";
	font-display: swap;
	src: url("/src/fonts/wotfard-light-webfont.woff2") format("woff2");
	font-weight: normal;
	font-style: normal;
}


