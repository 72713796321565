html {
	scroll-behavior: smooth;
}

// section {
// 	&::before {
// 		content: "";
// 		// display: block;
// 		// height: 100px;
// 		// margin: -100px 0 0;
// 		visibility: hidden;
// 		height: 0.1px !important;
// 		position: absolute;
// 		margin: -100px;
// 	}
// }

// iframe {
// 	&::before {
// 		content: "";
// 		display: block;
// 		height: 100px;
// 		margin: -100px 0 0;
// 		// visibility: hidden;
// 		// height: 0.1px !important;
// 		// position: absolute;
// 		// margin: -100px;
// 	}
// }

// global swiper flickering fix
.swiper-slide {
	/* stylelint-disable */
	-webkit-transform: translate3d(0, 0, 0);
	/* stylelint-enable */
}

// for media-queries use:
// 		@screen md {
// 			...
// 		}

header {
	.logo {
		a {
			svg {
				@apply w-logo-mobile;

				@screen md {
					@apply w-logo;
				}
			}
		}
	}
}

footer {
	.footer-button {
		border-right: 1px solid #ffff;

		&:last-child {
			@apply border-0;
		}
	}

	.logo-icon-footer {
		svg {
			@apply w-logo-icon-mobile;

			@screen md {
				@apply h-logo-icon;
				@apply w-logo-icon;
			}
		}
	}
}

.main-header {
	.floating-button-icon {
		z-index: 1;
		
		svg {
			height: 110px;
			width: 110px;

			@screen md {
				height: 159px;
				width: 159px;
			}
		}

		&:hover {
			animation: rotation 10s infinite linear;
		}
	}
}

@keyframes rotation {
	from {
		transform: rotate(0deg);
	}

	to {
		transform: rotate(359deg);
	}
}

.sprung {
	visibility: hidden;
	height: 0.1px !important;
	position: absolute;
	margin: -100px;
}

.hyphens-auto {
	@media (max-width: 1280px) {
		hyphens: auto;
	}
}
