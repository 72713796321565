.video-overlay {
	position: fixed; /* Sit on top of the page content */
	display: none; /* Hidden by default */
	width: 100%; /* Full width (cover the whole page) */
	height: 100%; /* Full height (cover the whole page) */
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: rgba(0, 0, 0, 0.5); /* Black background with opacity */
	z-index: 25; /* Specify a stack order in case you're using a different order for other elements */
	cursor: pointer; /* Add a pointer on hover */

	.iframe-container {
		display: flex;
		margin: 0 auto;

		@screen md {
			width: fit-content;
		}


		.aspect-container {
			position: relative;
			padding-bottom: 56.25%;
	
			iframe {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
			}
		}
		
	}
}
