.accordion,
.accordion-mobile {
	.category-separator {
		@apply absolute;
		@apply bottom-0;
		@apply left-0;
		@apply w-full;
		@apply border-solid;
		@apply border-heading-dark;
		@apply mb-4;
	}

	.accordion-rich-textfield {
		p {
			@apply mb-6;
			@apply text-body-text;
			@apply font-wotfard-light;
			@apply leading-body;
		}

		h4 {
			@apply text-h4-mobile;
			@apply leading-h4-mobile;
			@apply font-raleway-bold;
			@apply mb-4;

			@screen md {
				@apply text-h4;
				@apply leading-h4;
				@apply mb-6;
			}
		}

		a {
			@apply text-overline-mobile;
			@apply tracking-overline-mobile;
			@apply uppercase;
			@apply my-4;
			@apply font-raleway-medium;
			@apply flex;

			@screen md {
				@apply text-overline;
				@apply tracking-overline;	
			}

			&::after {
				content: "";
				background-image: url("../../images/ic_arrow_forward.png");
				display: inline-flex;
				width: 1.5rem;
				height: 1.5rem;
				background-repeat: no-repeat;
				background-size: contain;
				@apply ml-2;
			}
		}


	}
}
