.sprig-component,
.teaser-div {
	.blog-entry-card {
		&:hover {
			.button-author {
				svg {
					@apply ml-4;
				}
			}
		}
	}

	.button-author {
		width: fit-content;

		&:hover {
			svg {
				@apply ml-4;
			}
		}

		.button-blog-details {
			width: fit-content;
			
			svg {
				@apply transition-all;
			}
		}
	}

	.swiper-slide {
		&.active {
			@apply bg-grey;
		}
	}
}

.slider-filter-buttons-blog {
	.filter-button-blog {
		&:hover {
			@apply md:border-black;
		}
	}
}

.load-more-button {
	@apply uppercase;
	@apply hover:underline;
	@apply justify-center;
	@apply items-center;
	@apply border;
	@apply font-raleway-medium;
	@apply tracking-widest; 
	@apply text-heading-dark;
	@apply text-button;
	@apply leading-body-small;
	@apply md:leading-button;
	@apply mb-6;
	@apply border-heading-dark;
	@apply px-button-x-mobile;
	@apply py-button-y-mobile;
	@apply md:py-4; 
	@apply md:px-10;
	@apply md:mx-1;
	@apply absolute;
	@apply left-0;
	@apply right-0;
	@apply mx-auto;
	width: fit-content;
}


.sprig-component {
	&.htmx-request {
		#blog-filter-results {
			z-index: -1;
			@apply opacity-0;
		}

		.loading-cards {
			z-index: 10;
		}

		.news-container {
			z-index: 0;
			opacity: 0;
		}
	}

	.loading-cards {
		z-index: 0;
	}

	.loading-container {
		.lds-ring {
			@apply inline-block;
			@apply relative;
			@apply md:w-20;
			@apply md:h-20;
			@apply h-16;
			@apply w-16;
	
			div {
				@apply block;
				@apply absolute;
				@apply m-2;
				@apply box-border;
				@apply w-12;
				@apply h-12;
				@apply h-8;
				@apply w-8;
				border: 5px solid #f4976b;
				border-radius: 50%;
				animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
				border-color: #f4976b transparent transparent;
	
				&:nth-child(1) {
					animation-delay: -0.45s;
				}
	
				&:nth-child(2) {
					animation-delay: -0.3s;
				}
	
				&:nth-child(3) {
					animation-delay: -0.15s;
				}
			}
		}
	}
	
	
	@keyframes lds-ring {
		0% {
			transform: rotate(0deg);
		}
	
		100% {
			transform: rotate(360deg);
		}
	}

	&.htmx-swapping {
		.loading-container {
			@apply flex;
		}

		#blog-filter-results {
			opacity: 0;
		}
	}





	.load-more-container {
		.loading-container {
			z-index: -1;
			@apply opacity-0;
			transition: opacity 0.3s ease;
		}

		button {
			&.htmx-request {
				z-index: -1;
				@apply opacity-0;

				~ .loading-container {
					@apply z-10;
					@apply opacity-100;
				}
			}

			&.htmx-settling {
				z-index: -1;
				@apply opacity-0;

				~ .loading-container {
					@apply z-10;
					@apply opacity-100;
				}
			}
		}

		#indicator {
			&.htmx-request {
				z-index: -1;
				@apply opacity-0;

				~ .loading-container {
					@apply z-10;
					@apply opacity-100;
				}
			}
		}
	}
}
