.redactor-rich-textfield {
	@apply mt-6;
	@apply font-wotfard-light;
	word-break: break-word;

	@screen md {
		@apply mt-20;
	}

	h1 {
		@apply font-raleway-bold;
		@apply text-h1-mobile;
		@apply leading-h1-mobile;
		@apply mb-8;
		
		@screen md {
			@apply text-h1;
			@apply leading-h1;
		}
	}

	h2 {
		@apply font-raleway-bold;
		@apply text-h2-mobile;
		@apply leading-h2-mobile;
		@apply mb-4;

		@screen md {
			@apply text-h2;
			@apply leading-h2;
		}
	}

	p {
		@apply font-wotfard-light;
		@apply text-body;
		@apply leading-body;
		@apply mb-4;
	}
}
