.fui-i {
	@apply font-wotfard-light;
	@apply text-white;
	
	.fui-input,
	.fui-select {
		@apply bg-transparent;
		@apply rounded-none;
		@apply text-white;
		@apply font-wotfard-light;
	}
	
	.fui-btn,
	.fui-submit,
	.submit-button-newsletter {
		@apply bg-transparent;
		@apply rounded-none;
		@apply text-white;
		@apply text-overline;
		@apply font-raleway-medium;
		@apply border-white;
		padding-top: 7px;
		padding-bottom: 7px;
		padding-left: 27px;
		padding-right: 27px;
		@apply tracking-widest;
		@apply block;
		@apply w-full;

		@screen md {
			@apply w-auto;
			@apply inline;
			@apply mx-auto;
		}

		&:hover {
			@apply bg-transparent;
			@apply underline;
			@apply border-white;

		}
	}

	.fui-btn-container {
		@media (max-width: 640px) {
			@apply p-0;
			justify-content: left;
		}
	}

	#fields-newsletter-form-vorname-firstName,
	#fields-newsletter-form-vorname-lastName,
	#fields-newsletter-form-e-mail {
		@apply bg-transparent;
		@apply rounded-none;
		@apply text-white;
		@apply border-none;
		border-bottom: 1px solid #fff;
		@apply pl-0;
		@apply font-wotfard-light;
	}

	.fui-checkbox-label {
		@apply text-white;
		@apply font-wotfard-light;

		&::before {
			@apply bg-transparent;
			border-radius: 1px;
			border: 1.5px solid #fff;
		}

		a {
			@apply underline;
		}
	}

	.fui-input-container {
		.fui-input {
			@apply bg-transparent;
			@apply rounded-none;
			@apply text-white;
			@apply border-none;
			border-bottom: 1px solid #fff;
			@apply pl-0;
			@apply font-wotfard-light;
		}
	}
}
