.cookie-banner-tab {
	@apply flex;
	@apply fixed;
	@apply cursor-pointer;
	@apply rounded-t-xl;
	@apply left-2;
	@apply bg-grey;
	bottom: -30px;
	transition: bottom 0.3s ease;
	@apply font-wotfard-light;

	&:hover {
		@apply bottom-0;
	}
}

#cookie-banner {
	.cookie-notice {
		@apply w-full;
		@apply max-w-full;
		@apply left-0;
		@apply right-0;
		@apply bottom-0;

		.cn-body {
			@apply bg-grey;
			@apply flex;
			@apply p-4;
			@apply w-full;
			@apply flex-col;
			@apply font-wotfard-light;

			@screen xl {
				@apply flex-row;
				@apply items-center;
				@apply justify-between;
			}

			p {
				@apply text-heading-dark;
				@apply w-full;
				@apply max-w-full;

				@screen xl {
					@apply w-3/5;
				}

				span {
					@apply max-w-full;

					a {
						@apply text-black;
					}
				}
			}

			.cn-ok {
				@apply mt-2;

				@screen xl {
					@apply mt-0;
				}
			}

			.cn-btn-success {
				border: 1px solid #000;
				@apply uppercase;
				@apply text-white;
				@apply border;
				@apply border-black;
				@apply py-4;
				@apply px-6;
				@apply mr-0;
				@apply w-auto;
				@apply tracking-widest;
				@apply bg-transparent;
				@apply rounded-none;
			}

			.cn-learn-more {
				@apply text-black;
				@apply mx-4;
			}
		}
	}

	.cm-modal {
		@apply bg-grey;
		@apply rounded-none;
		@apply font-wotfard-light;
		@apply text-heading-dark;

		h1 {
			@apply text-heading-dark;
		}

		p {
			@apply my-3;
			@apply text-heading-dark;
		}

		p,
		label span {
			@apply text-heading-dark;

			a {
				@apply text-black;
			}
		}

		.cm-caret {
			@apply text-heading-dark;

			a {
				@apply text-black;
			}
		}

		.cm-header,
		.cm-footer {
			@apply border-opacity-40;
			@apply px-6;
		}

		.cm-footer-buttons {
			@apply flex-col;
			@apply items-center;

			@screen md {
				@apply flex-row;
			}

			.cm-btn-success,
			.cm-btn-accept {
				@apply mt-4;
				@apply md:mt-0;
			}
		}

		.cm-body {
			@apply px-6;
			@apply py-6;
		}

		.cm-list-input:checked + .cm-list-label .slider {
			@apply bg-black;
			@apply opacity-100;
		}

		.cm-list-input &.half-checked + .cm-list-label .slider {
			@apply opacity-20;
		}

		.cm-list-input &.only-required + .cm-list-label .slider {
			@apply opacity-20;
		}

		.cm-list-input + .cm-list-label .slider {
			@apply opacity-20;
		}

		.cm-list-input .required:checked + .cm-list-label .slider {
			@apply bg-red;
		}

		.cm-list-input + .cm-list-label .slider {
			// 	&::before {
			// 		@apply bg-opacity-100;
			// 	}
		}

		.cm-purpose {
			@apply mb-6;

			&:last-child {
				@apply mb-0;
			}

			.purposes {
				@apply m-0;
			}

			// .cm-list-input {
			// 	&.half-checked {
			// 		.slider {
			// 			@apply opacity-10;
			// 		}
			// 	}
			// }

			.half-checked {
				@apply bg-black;
				@apply opacity-20;
			}
		}
	}

	.cn-buttons {
		@apply m-0;
		@apply flex;
		@apply w-full;
		@apply justify-between;
		@apply text-heading-dark;

		@screen sm {
			@apply w-auto;
			@apply justify-end;
		}
	}

	.cm-btn-success {
		border: 1px solid #000;
		background-color: #000;
		@apply uppercase;
		@apply text-white;
		@apply border-black;
		@apply px-4;
		@apply py-2;
		@apply md:py-4;
		@apply mr-0;
		@apply ml-2;
		@apply w-auto;
		@apply tracking-widest;
		@apply border;
		@apply rounded-none;

		&:hover {
			@apply underline;
		}
	}

	.cn-decline {
		@apply text-base;
		background: transparent;
		@apply hover:underline;
		@apply p-0;
		@apply text-left;
		@apply w-auto;
		@apply md:mr-4;
		@apply text-heading-dark;
	}

	.cm-btn-accept {
		@apply uppercase;
		@apply text-black;
		@apply border-black;
		@apply py-2;
		@apply px-3;
		@apply md:py-4;
		@apply md:px-6;
		@apply mr-0;
		@apply w-auto;
		@apply tracking-widest;
		@apply bg-transparent;
		@apply border;
		@apply rounded-none;

		&:hover {
			background: transparent;
			@apply underline;
		}
	}

	.cm-powered-by {
		@apply hidden;
	}
}

.cookie-modal {
	.cm-list-input {
		@apply hidden;
	}
}

.cm-modal {
	// @apply w-11/12;
	// @apply mx-auto;
	// @apply md:w-auto;

	.cm-list-input + .cm-list-label .slider {
		@apply opacity-100;
		@apply bg-black;
		@apply shadow-none;

		&::before {
			@apply bg-white;
		}
	}

	.cm-purposes .cm-purpose {
		.cm-switch {
			.slider {
				@apply bg-black;
				@apply opacity-20;

				&.active {
					@apply bg-black;
					@apply opacity-100;
				}
			}
		}
	}
}
