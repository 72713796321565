#header.active {
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
}

#header {
	&.header-shadow {
		box-shadow: 0.01px 2px 5px rgba(0, 0, 0, 0.01);
	}

	&.header-white {
		@apply bg-white #{!important};
		@apply transition;
		
		#menu {
			@apply bg-white #{!important};
			@apply transition;
		}
	}
}

.header-menu {
	@apply z-20;

	#header-buttons-menu {
		.header-buttons-primary {
			a {
				border-bottom: 1px solid transparent;
				transition: border-color 0.2s ease;

				&:hover {
					border-bottom: 1px solid #000;
				}

				&.active-tab {
					border-bottom: 1px solid #000;
				}
			}
		}
	}

	.menu-icon {
		@apply cursor-pointer;
		@apply right-2.5;
		@apply top-5;
		@apply select-none;
		@apply px-2.5;
		@apply py-5;
		@apply bg-transparent;
		@apply border-0;
		font-size: 0;

		&:focus {
			@apply outline-none;
		}

		.navicon {
			@apply bg-heading-dark;
			@apply block;
			@apply h-0.5;
			@apply relative;
			transition: all 0.2s ease-out;
			// @apply w-5;
			width: 36px;

			&::before,
			&::after {
				@apply bg-heading-dark;
				content: "";
				@apply block;
				@apply h-full;
				@apply absolute;
				transition: all 0.2s ease-out;
				@apply w-full;
				// width: 36px;
			}

			&::before {
				@apply top-2.5;
			}

			&::after {
				@apply -top-2.5;
			}
		}

		&.close {
			.navicon {
				@apply bg-transparent;

				&::before {
					@apply transform;
					@apply -rotate-45;
				}

				&::after {
					@apply transform;
					@apply rotate-45;
				}
			}

			&:not(.steps) .navicon::before,
			&:not(.steps) .navicon::after {
				@apply top-0;
			}
		}
	}

	#menu {
		&.open {
			@apply flex;
			@apply flex-col;
			@apply absolute;
			@apply top-0;
			@apply inset-x-0;
			@apply w-full;
			@apply h-screen;
			height: calc(var(--vh, 1vh) * 100);
			@apply overflow-auto;
			@apply z-2;

			.buttons-menu-open {
				@apply px-4;
			}

			#header-buttons-menu {
				@apply flex;
				@apply flex-col;
				@apply mt-12;
				@apply px-8;
				@apply text-center;

				@screen xl {
					@apply gap-11;
				}

				ul {
					@apply flex;
					@apply flex-col;
				}
			}

			.menu-wrapper {
				@apply justify-between;
				@apply h-full;
				@apply overflow-auto;
				-webkit-overflow-scrolling: touch;
				@apply flex-grow;
			}
		}
	}

	.nav-menu {
		@apply flex-col;
		@apply h-full;
		@apply flex-grow;
		@apply items-start;

		@media (min-width: 1280px) {
			@apply flex-row;
		}
	}
}

header {
	.header-button {
		&.active {
			border-bottom: solid 1px;
		}
		
		&.focus {
			border-bottom: solid 1px;
		}
	}

	#header-buttons-menu {
		.praxis-button {
			border: 1px solid #719085;
			@apply px-20;
			@apply py-button-y-mobile;
			@apply mt-1.5;
			@apply flex;
			@apply items-center;

			@screen md {
				@apply md:py-3;
				@apply md:px-10;
				@apply mt-0;
			}
		}
	}
}
