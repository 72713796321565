.image-textfield-section {
	@apply font-wotfard-light;

	.redactor-textfield {
		.description-its {
			@apply mb-4;
			@apply text-body;
			@apply font-wotfard-light;

			p {
				@apply mb-4;
				@apply text-body;
				@apply font-wotfard-light;
			}
		}

		p {
			@apply text-overline-mobile;
			@apply font-wotfard-light;
			@apply mb-4;

			@screen md {
				@apply text-overline;
			}
		}

		ul {
			li {
				@apply mb-0.5;
				@apply list-disc;
				@apply list-outside;
				@apply ml-list-item;
				@apply text-body;
				@apply font-wotfard-light;

				@screen md {
					@apply mb-1;
				}
			}

			li::marker {
				@apply text-grey-light;
				@apply text-bullet-point;
			}
		}
	}
	
	.image-its {
		@apply border-none;

		@screen md {
			border: 40px solid #fff;
		}
	}
}
